<template>
  <div
    :class="signinClass"
    :style="{ backgroundImage: backgroundUrl }"
    class="signin-page"
  >
    <img
      v-if="isGC"
      alt=""
      class="signin-page__corner-img"
      src="../assets/images/logo_corner.png"
    />
    <img
      v-if="isLCM"
      alt=""
      class="signin-page__corner-img logo-corner-lcm"
      src="../assets/images/logo-corner-lcm.svg"
    />
    <div class="signin-page__wrapper">
      <!--Le titre -->
      <div class="signin-page__presentation md-small-hide">
        <h1
          :class="{ 'cl-alt': isJEP }"
          class="signin-page__presentation-title"
        >
          {{ appName }}
        </h1>

        <h2
          class="
            signin-page__presentation-title
            signin-page__presentation-title--normal
          "
          v-html="subTitle"
        />

        <div class="signin-page__apps">
          <a :href="iOsUrl">
            <img
              v-if="isGC"
              alt="apple_store"
              src="../assets/images/apple_store_gc.png"
            />
            <img
              v-if="isLCM"
              alt="apple_store"
              src="../assets/images/apple_store_lcm.png"
            />
            <img
              v-if="isJEP"
              alt="apple_store"
              src="../assets/images/apple_store_jep.png"
            />
            <img
              v-if="isCE"
              alt="apple_store"
              src="../assets/images/apple_store_ce.png"
            />
            <img
              v-if="isJEPA"
              alt="apple_store"
              src="../assets/images/apple_store_jepa.png"
            />
            <img
              v-if="isMEEP"
              alt="apple_store"
              src="../assets/images/apple_store_meep.png"
            />
          </a>
          <a :href="androidUrl">
            <img
              v-if="isGC"
              alt="apple_store"
              src="../assets/images/google_play_gc.png"
            />
            <img
              v-if="isLCM"
              alt="apple_store"
              src="../assets/images/google_play_lcm.png"
            />
            <img
              v-if="isJEP"
              alt="apple_store"
              src="../assets/images/google_play_jep.png"
            />
            <img
              v-if="isCE"
              alt="apple_store"
              src="../assets/images/google_play_ce.png"
            />
            <img
              v-if="isJEPA"
              alt="apple_store"
              src="../assets/images/google_play_jepa.png"
            />
            <img
              v-if="isMEEP"
              alt="apple_store"
              src="../assets/images/google_play_meep.png"
            />
          </a>
        </div>
        <div v-if="isMEEP" class="signin-page__apps-context">
          <p class="signin-page__apps-context-title">
            {{ $t("signin.app-context-title") }}
          </p>
          <p class="signin-page__apps-context-subtitle">
            {{ $t("signin.app-context-subtitle") }}
          </p>
          <p class="signin-page__apps-context-content">
            {{ $t("signin.app-context-content") }}
            <span class="signin-page__apps-context-bold">
              {{ $t("signin.app-context-bold") }}
            </span>
          </p>
        </div>
      </div>

      <!-- Le logo  -->
      <form class="meep-form signin-page__login" @submit.prevent="login">
        <div class="md-card-content">
          <md-icon
            v-if="logo"
            id="organization-logo"
            class="md-empty-state-icon"
          >
            <div v-html="logo" />
          </md-icon>

          <md-icon v-else id="organization-logo" class="md-empty-state-icon">
            <MeepIconLogoMeep />
          </md-icon>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('email') }">
              <label>{{ $t("email") }}</label>
              <md-input
                v-model="form.email"
                v-validate="'required|email'"
                autocomplete="username email"
                name="email"
                type="email"
              />
              <span v-show="errors.has('email')" class="md-error">
                {{ errors.first("email") }}</span
              >
            </md-field>
          </div>

          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('password') }">
              <label>{{ $t("password") }}</label>
              <md-input
                v-model="form.password"
                v-validate="'required'"
                autocomplete="current-password"
                name="password"
                type="password"
              />
              <span v-show="errors.has('password')" class="md-error">
                {{ errors.first("password") }}
              </span>
            </md-field>
          </div>
          <!-- Le bouton de connexion -->
          <md-button class="md-secondary md-raised signin-button" type="submit">
            {{ $t("login.cta") }}
          </md-button>

          <!-- Mot de passe oublié -->
          <router-link class="signin-page__link" to="/forgot-password">
            {{ $t("forgot-password.cta") }}
          </router-link>

          <!-- Créer un compte -->
          <router-link class="signin-page__link" to="/signup">
            {{ $t("create-account") }}
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import User from "../model/users.js";
import config from "../config";
import MeepIconLogoMeep from "./MeepIconLogoMeep.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "SignIn",

  components: {
    MeepIconLogoMeep,
  },

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      config: config,
    };
  },

  computed: {
    ...mapGetters([
      "organizationTheme",
      "backgroundUrl",
      "iOsUrl",
      "androidUrl",
      "isGC",
      "isLCM",
      "isJEP",
      "isCE",
      "isJEPA",
      "isMEEP",
      "organizationId",
    ]),

    ...mapState(["logo"]),

    signinClass() {
      return {
        "signin-page-gc": this.isGC,
        "signin-page--jepa": this.isJEPA,
        "app-jepa__background": this.isJEPA,
        "signin-page-lcm": this.isLCM,
        "signin-page--jep": this.isJEP,
        "signin-page--ce": this.isCE,
      };
    },
    appName() {
      let text = "meep.appName";
      if (this.isGC) text = "groupe-conseils.appName";
      if (this.isLCM) text = "lacomptamobile.appName";
      if (this.isJEP) text = "jep.appName";
      if (this.isCE) text = "ce.appName";
      if (this.isJEPA) text = "jepa.appName";

      return this.$t(text);
    },

    subTitle() {
      let text = "meep.mobile-install";

      if (this.isGC) text = "groupe-conseils.mobile-install";
      if (this.isLCM) text = "lacomptamobile.mobile-install";
      if (this.isJEP) text = "jep.mobile-install";
      if (this.isCE) text = "ce.mobile-install";
      if (this.isJEPA) text = "jepa.mobile-install";

      return `${this.$t(text)} ${this.$t("smartphone")}`;
    },
  },

  methods: {
    ...mapActions(["SIGNIN"]),

    async login() {
      const result = await this.$validator.validateAll();

      if (result) {
        try {
          this.form.organization_id = this.organizationId;
          const response = await this.SIGNIN(this.form);
          if (response.user.scope === 3) {
            const organizations = await User.getOrganizations(
              response.user.email,
            );

            if (organizations && organizations.length < 2) {
              await this.$router.push("/dashboard/");
            } else {
              await this.$router.push("/select-organization/");
            }
          } else {
            await this.$router.push("/dashboard/");
          }
        } catch (err) {
          console.log("Login error", err);

          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },
  },

  async mounted() {
    await this.$nextTick();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variable";

.md-theme-default {
  .signin-page {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

    &.signin-page--ce {
      .signin-page__presentation-title {
        font-family: var(--font-bold);
        margin-bottom: 21px;
        color: #ffffff;

        &--normal {
          font-family: var(--font);
        }
      }

      .signin-page__wrapper {
        .signin-page__login {
          .signin-page__link {
            color: #2a2a2a;
          }
        }
      }

      .md-field {
        label {
          color: #979797;
          font-size: 18px;
        }

        &.md-has-value,
        &.md-focused {
          label {
            font-size: 12px;
          }
        }
      }
    }

    &.signin-page--jep {
      .signin-page__presentation-title {
        font-family: "Gotham-Bold";
        margin-bottom: 21px;
      }

      .signin-page__presentation-title--normal {
        font-family: "Gotham-Light";
      }

      .signin-page__wrapper {
        .signin-page__login {
          #organization-logo {
            height: auto;
          }

          .signin-page__link {
            color: #2a2a2a;
          }
        }
      }

      .md-field {
        label {
          color: #979797;
          font-size: 18px;
        }

        &.md-has-value,
        &.md-focused {
          label {
            font-size: 12px;
          }
        }
      }
    }

    &.signin-page--jepa {
      font-family: "Gotham-Light";

      .signin-page__presentation-title {
        font-family: "Gotham-Bold";
        max-width: 600px;
        font-size: 59px;
        color: #002f87;
        font-weight: bold;

        &--normal {
          color: #ffffff;
          font-size: 31px;
          font-family: "Gotham-Thin";
          margin-top: 30px;
        }
      }

      .signin-page__wrapper {
        .signin-page__login {
          #organization-logo {
            div {
              height: auto;
            }
          }

          .signin-page__link {
            color: #2a2a2a;
          }
        }
      }

      .md-field {
        label {
          color: #979797;
          font-size: 18px;
        }

        &.md-has-value,
        &.md-focused {
          label {
            font-size: 12px;
          }
        }
      }

      .signin-page__link {
        font-size: 15px;
        color: #2a2a2a;
      }

      .signin-button {
        margin-bottom: 20px;
      }
    }

    &.signin-page-gc {
      .signin-page__presentation-title {
        color: #000000;
        text-shadow: none;
        font-weight: bold;
      }

      .signin-page__wrapper {
        .signin-page__login {
          #organization-logo {
            width: 155px;
          }
        }
      }
    }

    &.signin-page-lcm {
      .signin-page__presentation-title {
        color: #fff;
        font-family: var(--font-bold);
      }

      .signin-page__presentation-title--normal {
        font-family: var(--font);
        max-width: 450px;
      }

      .signin-page__login {
        flex: 35% 0 0;
      }

      .md-input {
        height: 50px;
      }

      .md-field {
        label {
          color: #979797;
          font-size: 18px;
        }

        &.md-has-value,
        &.md-focused {
          label {
            font-size: 12px;
          }
        }
      }

      .signin-page__link {
        font-family: var(--font-x);
        font-size: 16px;
      }
    }

    &.signin-page-gc {
      .signin-page__presentation-title {
        color: #000;
        font-family: var(--font-bold);
      }

      .signin-page__presentation-title--normal {
        font-family: var(--font);
      }

      .signin-page__login {
        flex: 35% 0 0;
      }

      .md-input {
        height: 50px;
      }

      .md-field {
        label {
          color: #979797;
          font-size: 18px;
        }

        &.md-has-value,
        &.md-focused {
          label {
            font-size: 12px;
          }
        }
      }

      .signin-page__link {
        font-family: var(--font-x);
        font-size: 16px;
      }
    }

    &__corner-img {
      position: absolute;
      transform: rotate(90deg);
      top: 0;
      left: 0;
      width: 20%;

      &.logo-corner-lcm {
        top: 32px;
        width: 20em;
        transform: rotate(0) translate(-1em);
      }
    }

    &__wrapper {
      height: 100%;
      max-width: 1080px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .signin-button {
      width: 50%;
      box-shadow: none;
    }

    a,
    a:hover {
      margin-top: 10px;
    }

    &__login {
      background-color: #ffffff;
      flex: 35% 0 0;
      padding: 50px 40px 80px;
      box-shadow: 0px 0px 1px black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 27px;
      box-shadow: 2px 2px 25px 0 rgba(77, 77, 77, 0.2);

      .md-icon {
        width: 235px;
        font-size: 24px;
      }

      .md-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .meep-input {
          .md-field.md-has-value.md-has-password {
            [type="password"].md-input {
              padding: toRem(10) toRem(50) 0 toRem(12);
              @include for-lg{
                padding: toRem(12) toRem(50) 0 toRem(19);
              }
            }
          }
        }
      }
    }

    &__logo {
      margin: 20px;
    }

    &__apps {
      margin-top: 42px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      a {
        position: relative;
        margin-right: 27px;
        flex-basis: 170px;
        flex-grow: 0;
        flex-shrink: 1;
      }

      &-context {
        background: rgba(255, 255, 255, 0.42);
        box-shadow: 2px 2px 25px 0 rgba(77, 77, 77, 0.2);
        border-radius: 27px;
        max-height: 250px;
        max-width: 90%;
        padding: 45px;
        margin-top: 60px;

        &-title {
          font-family: var(--font-extrabold);
          font-size: 19px;
          margin-bottom: 2px;
        }

        &-subtitle {
          font-family: var(--font-bold);
          font-size: 18px;
          margin: 5px 0 15px;
        }

        &-content {
          font-size: 17px;
          color: var(--text-primary);
        }

        &-bold {
          font-family: var(--font-extrabold);
        }
      }
    }

    &__app-icon {
      border-radius: 5px;
      background-color: white;
      width: 160px;
      height: auto;

      &.app-icon-lcm {
        padding: 2px;
      }
    }

    &__presentation-title {
      color: #000;
      font-family: var(--font-extrabold);
      line-height: initial;
      font-size: 59px;
      margin-bottom: 0px;
      margin-top: 0;

      &--normal {
        color: #fff;
        font-family: var(--font-x);
        margin: 0;
        font-size: 31px;
        font-weight: normal;
      }
    }

    &__link {
      font-size: 16px;
      font-family: var(--font);
      text-decoration: none;
    }

    .signin-page__logo {
      margin-bottom: toRem(52);
    }
  }
}

#organization-logo {
  margin-bottom: toRem(52);
  align-items: flex-end;

  /deep/ svg {
    width: 100%;
    height: auto;
  }
}
</style>
